<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12" class="text-center">
        <v-alert type="error" border="left">
          {{ $vuetify.lang.t('$vuetify.pageNotFound') }}
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PageNotFound',
  props: {
    title: String
  },
  mounted () {
    if (this.title) {
      document.title = process.env.VUE_APP_NAME + ': ' + this.title
    }
  }
}
</script>

<style scoped>

</style>
